<template>
  <div class="d-flex flex-column fill-height">
    <div id="map" class="map" ref="map">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'underscore'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import ExportToExcel from '@/util/json_to_excel.service'
import { periods } from '@/util'

const ol = window.ol

export default {
  name: 'IncidentsHeatMapReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      map: null,
      initZoom: 12,
      routes: [],
      mapRoutes: {},
      devicesSelected: {},
      selected: null,
      selectedIndex: null,
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59'
      },
      filtersForm: {
        category: {
          label: 'Categoria',
          type: String,
          optional: true,
          options: [
            { id: 'seguridad', name: 'Seguridad' },
            { id: 'medico', name: 'Médico' },
            { id: 'proteccion_civil', name: 'Protección Civil' },
            { id: 'servicios_publicos', name: 'Servicios Públicos' }
          ]
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  mounted() {
    this.map = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
      })
    })
    this.satelliteMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
      })
    })
    this.satelliteMap.setVisible(false)
    this.darkmodeMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        // url: '//api.maptiler.com/maps/a881ff38-8ab2-49ee-baae-c8ac0a1ede8f/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
        url: '//api.maptiler.com/maps/6dea3d11-51b5-48e9-997b-ebba1fdfefe4/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
      })
    })
    this.darkmodeMap.setVisible(this.mapMode === 'night')

    this.mapView = new ol.View({
      center: this.getMapCenter || ol.proj.fromLonLat([
        this.getUser.longitude, this.getUser.latitude
      ]),
      zoom: this.initZoom
    })

    this.heatSource = new ol.source.Vector({});

    const blur = 20;
    const radius = 10;

    this.heatMapLayer = new ol.layer.Heatmap({
      title: 'HeatMap',
      source: this.heatSource,
      blur,
      radius,
      weight: () => {
        return 10;
      }
    });

    this.olmap = new ol.Map({
      target: 'map',
      layers: [
        this.map,
        this.darkmodeMap,
        this.satelliteMap,
        this.heatMapLayer
      ],
      view: this.mapView
    })
  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        category: this.filtro.category,
        ...this.period
      }
      try {
        this.itemsTable = await ApiService({
          url: '/incidents/reports/range',
          method: 'get',
          params: data
        })
        const heatMapData = {
          type: 'FeatureCollection',
          features: this.itemsTable.map((item) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: window.ol.proj.fromLonLat([item.longitude, item.latitude])
            },
            properties: { title: item.name, id: item.id, name: item.name }
          }))
        }
        this.heatSource.clear()
        this.heatSource.addFeatures(new ol.format.GeoJSON().readFeatures(heatMapData))
        window.VMA.loading(false)
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            if (this.monthDiff(this.period.from, this.period.to) > 0) {
              window.VMA.showMessage({ color: 'error', body: 'Máximo un mes de rango' })
              return
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    toExcel() {
      ExportToExcel(
        'Rutas de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: this.devicesSelected[item.deviceId].name,
          Longitud: item.longitude,
          Latitud: item.latitude,
          Velocidad: item.speed,
          'Fecha y Hora': moment(item.serverTime).format('DD-MM-YYYY HH:mm')
        }))
      )
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
  },
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
</style>
